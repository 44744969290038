import Vue from 'vue'
import Router from 'vue-router'
import Login from './components/login.vue'
import timesheet from './components/timesheet.vue'
import profile from './components/profile.vue'
import register from './components/register.vue'
import dash from './components/dash.vue'
import info from './components/info.vue'
import messages from './components/messages.vue'
import changepassword from './components/changepassword.vue'
import holiday from './components/holiday.vue'
import disease from './components/disease.vue'
import filterusers from './components/filterusers.vue'
import users from './components/users.vue'
import editUser from './components/editUser.vue'
import editSpesa from './components/editSpesa.vue'
import changepasswordadmin from './components/changepasswordadmin.vue'
import settings from './components/settings.vue'
import changepasswordinitial from './components/changepasswordinitial.vue'
import reservate from './components/reservate.vue'
import firma from './components/firma.vue'
import sendMessage from './components/sendMessage.vue'
import spese from './components/spese.vue'
import editSpesaDetails from './components/editSpesaDetails.vue'
import approvazione from './components/approvazione.vue'
import editSpesaDetailsAdmin from './components/editSpesaDetailsAdmin.vue'
import discover from './components/discover.vue'
import discoversettings from './components/discoversettings.vue'
import reloadPage from './components/reloadPage.vue'
import logoFirma from './components/logoFirma.vue'
import filterGroups from './components/filterGroups.vue'
import groups from './components/groups.vue'
import editGroup from './components/editGroup.vue'
import editGroupSelUsers from './components/editGroupSelUsers.vue'
import editGroupMails from './components/editGroupMails.vue'
import editGroupEditMail from './components/editGroupEditMail.vue'
import reservateForInsert from './components/reservateForInsert.vue'


Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            component: Login
        },

        {
            path: '/discover',
            name: 'discover',
            component: discover
        },
        {
            path: '/discoversettings',
            name: 'discoversettings',
            component: discoversettings
        },
        {
            path: '/dash',
            name: 'dash',
            component: dash
        },


        {
            path: '/timesheet',
            name: 'timesheet',
            component: timesheet
        },

        {
            path: '/category/edit-timesheet',
            name: 'timesheet',
            component: timesheet
        },

        {
            path: '/profile',
            name: 'profile',
            component: profile
        },



        {
            path: '/register',
            name: 'register',
            component: register
        },

        {
            path: '/category/modfiy-account',
            name: 'profile',
            component: profile
        }
        ,
        {
            path: '/category/timesheet',
            name: 'timesheet',
            component: timesheet
        }
        ,
        {
            path: '/info',
            name: 'info',
            component: info
        }

        ,
        {
            path: '/messages',
            name: 'messages',
            component: messages
        }


        ,
        {
            path: '/holiday',
            name: 'holiday',
            component: holiday
        }
        ,
        {
            path: '/disease',
            name: 'disease',
            component: disease
        }

        ,
        {
            path: '/filterusers',
            name: 'filterusers',
            component: filterusers
        }


        ,
        {
            path: '/users',
            name: 'users',
            component: users
        }

        ,
        {
            path: '/editUser/:id',
            name: 'editUser',
            component: editUser,
            props: true
        }
        ,
        {
            path: '/editSpesa/:id',
            name: 'editSpesa',
            component: editSpesa,
            props: true
        }

        ,
        {
            path: '/changepassword/:id',
            name: 'changepassword',
            component: changepassword,
            props: true
        }

        ,
        {
            path: '/changepasswordadmin/:id',
            name: 'changepasswordadmin',
            component: changepasswordadmin,
            props: true
        },


        {
            path: '/settings',
            name: 'settings',
            component: settings
        },


        {
            path: '/changepasswordinitial',
            name: 'changepasswordinitial',
            component: changepasswordinitial
        }

        ,
        {
            path: '/reservate/:id',
            name: 'reservate',
            component: reservate,
            props: true
        }

        ,
        {
            path: '/firma',
            name: 'firma',
            component: firma
        }

        ,
        {
            path: '/sendMessage',
            name: 'sendMessage',
            component: sendMessage
        }

        ,
        {
            path: '/spese',
            name: 'spese',
            component: spese
        }

        ,
        {
            path: '/editSpesaDetails/:id',
            name: 'editSpesaDetails',
            component: editSpesaDetails,
            props: true
        }

        ,
        {
            path: '/approvazione',
            name: 'approvazione',
            component: approvazione
        }

        ,
        {
            path: '/editSpesaDetailsAdmin/:id',
            name: 'editSpesaDetailsAdmin',
            component: editSpesaDetailsAdmin,
            props: true
        }

        ,
        {
            path: '/reloadPage',
            name: 'reloadPage',
            component: reloadPage
        }

        ,
        {
            path: '/logoFirma',
            name: 'logoFirma',
            component: logoFirma
        }

        ,
        {
            path: '/filterGroups',
            name: 'filterGroups',
            component: filterGroups
        }

        ,
        {
            path: '/groups',
            name: 'groups',
            component: groups
        }


        ,
        {
            path: '/editGroup/:id',
            name: 'editGroup',
            component: editGroup,
            props: true
        }

        ,
        {
            path: '/editGroupSelUsers',
            name: 'editGroupSelUsers',
            component: editGroupSelUsers
        }

        ,
        {
            path: '/editGroupMails',
            name: 'editGroupMails',
            component: editGroupMails
        },

        {
            path: '/editGroupEditMail/:id',
            name: 'editGroupEditMail',
            component: editGroupEditMail,
            props: true
        },

        {
            path: '/reservateForInsert/:id',
            name: 'reservateForInsert',
            component: reservateForInsert,
            props: true
        },

    ]
})


router.afterEach((to, from) => {
    console.log("from", from);
    console.log("going to " + to.fullPath)


})


export default router
